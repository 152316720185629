<template>
<div class="LoginContent d-flex justify-end">
	<!--LOGO MOVIL-->
	<div 
	v-if="IsMovil"
	class="LogoCont">
		<img class="LogoMovil" 
			src="@/assets/img/logo.svg" 
		/>
	</div>
	<!--COMPONENTES-->
  <router-view />
</div>
</template>
<style lang="less" scoped>
.LogoCont{
	width: 100%;
	height: auto;
	position: absolute;
	top: 7%;
	display: flex;
	justify-content: center;
	align-items: center;

	.LogoMovil{
		width: 150px;
	}
}
</style>
<script>
export default{
	name:'Login',
	computed:{
		IsMovil(){
			if(
				window.innerWidth < 600 &&
				this.$route.path !='/login'
			){
				return true
			}else{
				return false;
			}
		}
	}
}
</script>
